import axios, { isAxiosError } from 'axios';

import { getAuthHeaders } from 'modules/download-audio/api/authHeaders';
import { SkipContentSettings } from 'modules/listening/features/settings/settings';
import { VoiceItemUIProp } from 'modules/voices/types';

import { getRequiredStringEnv } from '../../../utils/safeEnvParsing';

export const EXPORT_SEVICE_URL = getRequiredStringEnv('NEXT_PUBLIC_EXPORT_SEVICE_URL');

async function getSkipSettings(): Promise<SkipContentSettings> {
  const { useListeningSettingsStore: settingsStore } = await import('modules/listening/features/settings/settingsStore');
  const skipSettings = settingsStore.getState();
  return {
    ...skipSettings,
    enhancedSkipping: false
  };
}

type TriggerDownloadProps = {
  voice: {
    name?: string;
    engine?: string;
    language: string;
  };
  pages?: number[];
  skipSettings: SkipContentSettings;
};

export const triggerDownloadProcess = async (itemId: string, voice: VoiceItemUIProp, pages?: number[]): Promise<string> => {
  const options = await getAuthHeaders();
  const body: TriggerDownloadProps = {
    voice: {
      name: voice.name,
      engine: voice.engine,
      language: voice.languageCodeWithLocale
    },
    skipSettings: await getSkipSettings()
  };

  if (pages) {
    body.pages = pages;
  }

  const { data } = await axios.post<{ exportTaskId: string }>(`${EXPORT_SEVICE_URL}/library-items/v1/${itemId}/export`, body, options);

  return data.exportTaskId;
};

export const fetchFileData = async (exportTaskId: string) => {
  const options = await getAuthHeaders();
  const { data: fileData } = await axios.get<Blob>(`${EXPORT_SEVICE_URL}/tasks/v1/${exportTaskId}/download`, {
    responseType: 'blob',
    ...options
  });

  return fileData;
};

type ExportStatus = 'in-progress' | 'success' | 'failure';

type StatusResponse = {
  id: string;
  status?: ExportStatus;
  progress?: number;
};

export const fetchStatus = async (exportTaskId: string, signal?: AbortSignal): Promise<Omit<StatusResponse, 'id'>> => {
  const statusUrl = `${EXPORT_SEVICE_URL}/tasks/v1/${exportTaskId}/status`;
  const options = {
    ...(await getAuthHeaders()),
    signal
  };
  const { data } = await axios<StatusResponse>(statusUrl, options).catch(e => {
    const statusCode = isAxiosError(e) ? e.response?.status : undefined;
    // Check for 4xx or 500 errors from export service
    // 5xx errors mean that request was not processed (e.g. service is down) and we should retry
    const isServiceError = statusCode && statusCode >= 400 && statusCode <= 500;
    const data: Omit<StatusResponse, 'id'> = {
      status: isServiceError ? 'failure' : undefined,
      progress: undefined
    };

    return { data };
  });

  return data;
};

type EstimateDurationProps = {
  pages?: number[];
  skipSettings: SkipContentSettings;
};

type EstimatedDurationResponse = {
  estimatedDurationSeconds: number;
};

export const fetchEstimatedDuration = async (itemId: string, pages?: number[], signal?: AbortSignal): Promise<number> => {
  const options = {
    ...(await getAuthHeaders()),
    signal
  };
  const body: EstimateDurationProps = {
    skipSettings: await getSkipSettings()
  };

  if (pages) {
    body.pages = pages;
  }

  const { data } = await axios.post<EstimatedDurationResponse>(`${EXPORT_SEVICE_URL}/library-items/v1/${itemId}/get-estimate-duration`, body, options);

  return data.estimatedDurationSeconds;
};
