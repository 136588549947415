import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useInterval } from 'hooks/useInterval';
import { useTranslation } from 'hooks/useTypedTranslation';
import { AnalyticsEventKey, logAnalyticsEvent } from 'modules/analytics/logAnalyticsEvent';
import { removeExportingLibraryItem, updateExportingLibraryItem, useExportingLibraryItemsStore } from 'modules/download-audio/exportStore';
import { addToast } from 'modules/toast/stores/actions';

import { fetchFileData, fetchStatus } from '../../api/exportService';
import { triggerDownloadFile } from './helpers';

export const useExportItemStatusCheck = (itemId: string) => {
  const item = useExportingLibraryItemsStore(state => state.items[itemId]);
  const isExporting = item.status === 'exporting';
  const exportTaskId = item.exportTaskId;

  const [isScheduled, setIsScheduled] = useState(isExporting);
  const abortController = useMemo(() => new AbortController(), []);

  const checkExportStatus = useCallback(async () => {
    // Status fetch can fail due to network issues, aborting, service issues, etc.
    // Unless we explicitely get failed status, we should keep checking
    const { progress, status } = await fetchStatus(exportTaskId, abortController.signal);

    if (progress) updateExportingLibraryItem(itemId, { progress: progress * 100 });

    setIsScheduled(status !== 'success' && status !== 'failure');

    if (status === 'success') updateExportingLibraryItem(itemId, { status: 'downloading' });
    if (status === 'failure') updateExportingLibraryItem(itemId, { status: 'failed' });
  }, [itemId, exportTaskId, abortController.signal]);

  useEffect(() => {
    return () => abortController.abort();
  }, [abortController]);

  useInterval(checkExportStatus, isScheduled && !abortController.signal?.aborted ? 2000 : null);
};

export const useDownloadAudioFile = (itemId: string) => {
  const item = useExportingLibraryItemsStore(state => state.items[itemId]);
  const itemRef = useRef(item);
  const isFailed = itemRef.current.status !== 'failed' && item.status === 'failed';
  const isSucceed = itemRef.current.status !== 'success' && item.status === 'success';
  const isReadyToDownload = item.status === 'downloading';

  const [fileSize, setFileSize] = useState<number | null>(null);

  const { t } = useTranslation('common');

  const showToast = useCallback((content: string | undefined, type: 'error' | 'success') => {
    addToast({ title: '', description: content ?? '', duration: 60 * 1000, type });
  }, []);

  useExportItemStatusCheck(itemId);

  // Runs when the item is failed during current lifecycle
  // Don't react to stale data, e.g. after page reload but the item is still in store
  useEffect(() => {
    if (!isFailed) return;

    logAnalyticsEvent(AnalyticsEventKey.mp3DownloadError);
    showToast(t('Download failed!'), 'error');
  }, [isFailed, t, showToast]);

  // Runs when the item is ready to download
  // Important: react stale data, e.g. after page reload but the item is still in store
  // Reason: downloading state means that item was not downloaded yet
  useEffect(() => {
    if (!isReadyToDownload) return;

    const { exportTaskId, title } = itemRef.current;

    fetchFileData(exportTaskId).then(fileData => {
      setFileSize(fileData.size);
      triggerDownloadFile(title || 'audio', fileData);
      updateExportingLibraryItem(itemId, { status: 'success' });
    });
  }, [isReadyToDownload, itemId]);

  // Runs when the item is successfully downloaded
  // Don't react to stale data, e.g. after page reload but the item is still in store
  useEffect(() => {
    if (!isSucceed || !fileSize) return;

    logAnalyticsEvent(AnalyticsEventKey.mp3DownloadCompleted, {
      duration: itemRef.current.estimatedDuration,
      file_size: fileSize,
      pages_downloaded: itemRef.current.pageCount,
      hours_remaining: itemRef.current.remainingQuota / 3600
    });
    showToast(t('Your MP3 file has been successfully downloaded!'), 'success');
    removeExportingLibraryItem(itemId);
  }, [isSucceed, t, showToast, itemId, fileSize]);
};
